var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_tax_invoice_sn") } },
    [
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.form,
                "align-left": "left",
                "label-col": { span: 6 },
                "wrapper-col": { span: 12 }
              }
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.companyName.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      name: _vm.formRules.companyName.name,
                      placeholder: _vm.$t(
                        _vm.formRules.companyName.placeholder
                      ),
                      disabled: true
                    },
                    model: {
                      value: _vm.form.companyName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.taxRegistrationNumber.label)
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      name: _vm.formRules.taxRegistrationNumber.name,
                      placeholder: _vm.$t(
                        _vm.formRules.taxRegistrationNumber.placeholder
                      ),
                      disabled: true
                    },
                    model: {
                      value: _vm.form.taxRegistrationNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "taxRegistrationNumber", $$v)
                      },
                      expression: "form.taxRegistrationNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between"
              }
            },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.disableButton,
                            icon: "plus"
                          },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.showConfirmation }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      dataSource: _vm.dataListTaxInvoiceSerialNumber,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(700px + 50%)" },
                      paginationcustom: true,
                      defaultPagination: false,
                      loading: _vm.loadingTable,
                      onSelectChange: _vm.onSelectChange,
                      selectedRowKeys: _vm.selectedRowKeys,
                      handleInput: _vm.handleInput,
                      handleDateMonth: _vm.handleDateMonth,
                      handleSelect: _vm.handleSelectTable,
                      "label-return": _vm.$t("lbl_return_to_tax_office")
                    },
                    on: {
                      "on-view": _vm.reponseViewTable,
                      "on-download": _vm.handleDownload,
                      "on-return": _vm.handleReturn
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalData,
                      pageSizeSet: _vm.limit,
                      idPagination: "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.totalData) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", justify: "end" } },
            [
              _c(
                "a-col",
                [
                  _vm.$can("create", "tax-invoice-sn")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading.save },
                          on: { click: _vm.save }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }